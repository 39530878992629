// --
export const clone = (text: any) => {
  return JSON.parse(JSON.stringify(text))
}
// --
export const JSONTryer = (jsonString: any) => {
  try {
    return JSON.parse(jsonString)
  } catch (e) {
    return jsonString
  }
}