import { HttpHeaders } from '@angular/common/http'
import { JSONTryer } from './convert'

export function makeHeaders(o: any): any {
    for (const item in o) {
        o[item] = o[item].toString()
    }
    let objectHeader = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...o,
    }
    let headers: HttpHeaders = new HttpHeaders(objectHeader)
    return { headers: headers, observe: 'response' }
}

export function getArray(rs: Response): any[] {
    let data: any = rs.body
    if (data) {
        if (Array.isArray(data) == false) return [data]
        return data
    }
    return []
}

export const getObject = (response: Response, item: any = null): any => {
    let body: any = response.body
    if (body) {
        if (item) {
            if (Array.isArray(body)) {
                if (!body[0][item]) return null
                return body[0][item]
            }
            if (!body[item]) return null
            return body[item]
        }
        if (Array.isArray(body)) return body[0]
    }
    return body
}

export const getFilterContentCMS = (data: any, setArray: boolean = true, exclude: boolean = true) => {
    let filter = [
        'id',
        'status',
        'title',
        'locked',
        'author',
        'content_type',
        'created',
        'updated',
        'deleted',
        'password',
        'parentuuid',
        'contenttranslactionuuid',
        'isHidden',
        'Orden',
    ]
    let dataContent: any[] = []
    let isArray = Array.isArray(data)
    if (!isArray) data = [data]
    data.map((item: any) => {
        if (item) {
            if (item.hasOwnProperty('contents')) {
                let data_aux: any = {}
                item.contents.map((content: any) => {
                    if (exclude) {
                        if (!filter.includes(content.system_name)) {
                            data_aux[content.system_name] = JSONParse(JSONTryer(content.value))
                        }
                    }
                    else {
                        data_aux[content.system_name] = JSONParse(JSONTryer(content.value))
                    }
                })
                dataContent.push(data_aux)
            }
            else {
                if (!filter.includes(item.system_name)) {
                    dataContent.push({ ...item, value: JSONParse(JSONTryer(item.value)) })
                }
            }
        }
    })
    return setArray ? dataContent : dataContent[0]
}

export const JSONParse = (data: any): any => {
    if (data) {
        if (Array.isArray(data)) {
            data.map((x, i) => {
                data[i] = JSONParse(JSONTryer(x))
                if (x?.type && x?.uuid && x?.parent_system_name && x?.parent_name) {
                    if (x.type == 'embedded') {
                        if (x?.parent_system_name && x?.parent_name) {
                            data[i] = JSONParse({
                                _uuid: x?.uuid || '',
                                _parent_system_name: x?.parent_system_name || '',
                                _parent_name: x?.parent_name || '',
                            })
                        }
                        else {
                            data[i] = JSONParse(JSONTryer(x.value))
                        }
                    }
                }
            })
        }
        else if (typeof data == 'object') {
            if (Object.keys(data).length) {
                Object.keys(data).map(x => {
                    data[x] = JSONParse(JSONTryer(data[x]))
                })
            }
            else {
                data = ''
            }
        }
    }
    if (Array.isArray(data)) {
        if (data.length == 1) {
            data = data[0]
        }
    }
    return data ? data : ''
}