import { dynamicObservableService } from "./dynamic-observable.service";
import { GenericObservableService } from "./generic-observable.service";
import { LoaderObservableService } from "./loader-observable.service";
import { ModalObservableService } from "./modal-observable.service";

export * from "./dynamic-observable.service";
export * from "./generic-observable.service";
export * from "./loader-observable.service";
export * from "./modal-observable.service";

export const CoreObservableImports = [
  GenericObservableService,
  ModalObservableService,
  LoaderObservableService,
  dynamicObservableService
]