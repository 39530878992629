import { Injectable } from '@angular/core';
import { getArray, getFilterContentCMS } from 'src/app/_core/functions';
import { GenericObservableService } from 'src/app/_core/observables';
import { ApiService, SettingsService } from 'src/app/_core/services';
import { clone } from '../store/core';

@Injectable({
  providedIn: 'root',
})
export class CMSContentService {
  private pathInfo: any = {}
  private baseUrl: any = {}
  static module: any = {}
  static callServices: any = {}

  constructor(
    private settings: SettingsService,
    private api: ApiService,
    private genericObservable: GenericObservableService,
  ) {
    this.pathInfo = this.settings.get('pathInfo')
    this.baseUrl = this.pathInfo.base.baseUrlCms
  }

  async getContent(contentName: string, headers: any = {}) {
    let endpoint = this.baseUrl + (contentName.indexOf('/') == 0 ? contentName : `/${contentName}`)
    return await this.api.withAsync(() => this.api.get(endpoint, { ...headers }).toPromise().then((response: any) => response))
  }

  async getContentCMS(StoreModule: any, system_name?: string, uuid?: string): Promise<any> {
    if (!Boolean(CMSContentService.module[StoreModule.name])) {
      CMSContentService.module[StoreModule.name] = {}
    }
    // --
    let nameService = system_name || StoreModule.content
    let response = CMSContentService.callServices[nameService]
    let notFail: boolean = true
    if (response?.body && typeof response?.body == 'object') {
      // -- Si existe data
    }
    else {
      // CMSContentService.callServices[nameService] = {}
      const [res, error] = await this.getContent(nameService)
      CMSContentService.callServices[nameService] = res
      response = res
      if (error) {
        console.error(error)
        notFail = false
      }
    }
    if (notFail && getFilterContentCMS(getArray(response))) {
      if (system_name && uuid) {
        CMSContentService.module[StoreModule.name] = this.setContentSave(StoreModule, CMSContentService.module[StoreModule.name], getFilterContentCMS(getArray(response)), uuid)
      }
      else {
        CMSContentService.module[StoreModule.name] = getFilterContentCMS(getArray(response))
      }
      // --
      let serviceCall = this.setContentRecursive(StoreModule, CMSContentService.module[StoreModule.name]).filter((x: any) => x)
      if (serviceCall.length) {
        this.getContentCMS(serviceCall[0].module, serviceCall[0].service, serviceCall[0].uuid)
        return false
      }
      this.genericObservable.genericChange({
        method: 'getContentCMS',
        module: StoreModule.name,
        content: CMSContentService.module[StoreModule.name],
      })
    }
  }

  private setContentRecursive(StoreModule: any, content: any, servicesToCall: any = []): any {
    if (Array.isArray(content)) {
      content.map((x: any) => {
        this.setContentRecursive(StoreModule, x, servicesToCall)
      })
    }
    else {
      if (typeof content == 'object') {
        if (content?._uuid && content?._parent_system_name) {
          return servicesToCall.push({
            module: StoreModule,
            uuid: content._uuid,
            service: content._parent_system_name
          })
        }
        else {
          Object.values(content).map((prop: any): any => {
            if (prop) {
              this.setContentRecursive(StoreModule, prop, servicesToCall)
            }
          })
        }
      }
    }
    return servicesToCall
  }

  private setContentSave(StoreModule: any, contentParent: any, contentReplace: any, uuid: string): any {
    if (Array.isArray(contentParent)) {
      return contentParent.map((x: any) => {
        if (x) {
          x = this.setContentSave(StoreModule, x, contentReplace, uuid)
          return x
        }
      }).filter(x => x)
    }
    else {
      if (typeof contentParent == 'object') {
        if (contentParent?._uuid == uuid) {
          contentParent = contentReplace.find((x: any) => x.uuid == contentParent?._uuid)
          return contentParent
        }
        else {
          Object.keys(contentParent).map((prop: any): any => {
            if (prop) {
              return contentParent[prop] = this.setContentSave(StoreModule, contentParent[prop], contentReplace, uuid)
            }
          })
        }
      }
    }
    return contentParent
  }
}
