import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './pages/error/error.component';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./pages/layout.module').then(m => m.LayoutModule)
  },
  {
    path: 'landing', pathMatch: 'prefix', loadChildren: () => import('./landings/landings.module').then(m => m.LandingsModule)
  },
  {
    path: '**', component: ErrorComponent
  },
]
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0],
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
