import Store from "../core/core"

// interface ITest {
//     data?: string,
// }

export class OurServicesStore extends Store {
  protected override nameState: string = 'ourServices'
  protected override contentName: string = '/nuestros_servicios'
  protected override initialState: any = {}

  addContent(data: any) {
    return {
      ...this,
      newState: data
    }
  }
}