import { Test } from "./Test";
import { LoaderStore } from "./LoaderStore";
import { RRSSStore } from "./RRSSStore";
import { HomeStore } from "./HomeStore";
import { ServiceStore } from "./ServiceStore";
import { ContactStore } from "./ContactStore";
import { OurServicesStore } from "./OurServicesStore";
import { HistoryStore } from "./HistoryStore";
import { WorkProcessStore } from "./WorkProcessStore";
import { ApproachStore } from "./ApproachStore";
import { ClientsStore } from "./ClientsStore";
import { UsStore } from "./UsStore";
import { TemplatesStore } from "./TemplatesStore";
import { SeoStore } from "./SeoStore";
import { MenuStore } from "./MenuStore";
import { PrivacyStore } from "./PrivacyStore";
import { ConfigurationStore } from "./ConfigurationStore";

export * from "./Test";
export * from "./LoaderStore";
export * from "./RRSSStore";
export * from "./HomeStore";
export * from "./ServiceStore";
export * from "./ContactStore";
export * from "./OurServicesStore";
export * from "./HistoryStore";
export * from "./WorkProcessStore";
export * from "./ApproachStore";
export * from "./ClientsStore";
export * from "./UsStore";
export * from "./TemplatesStore";
export * from "./SeoStore";
export * from "./MenuStore";
export * from "./PrivacyStore";
export * from "./ConfigurationStore";

export const StoreProcedures = {
  Test,
  LoaderStore,
  RRSSStore,
  HomeStore,
  ServiceStore,
  ContactStore,
  OurServicesStore,
  HistoryStore,
  WorkProcessStore,
  ApproachStore,
  ClientsStore,
  UsStore,
  TemplatesStore,
  SeoStore,
  MenuStore,
  PrivacyStore,
  ConfigurationStore,
}