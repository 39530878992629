import { StoreProcedures } from "./Procedure";

export const StoreModule: any = {
  test: new StoreProcedures.Test,
  seo: new StoreProcedures.SeoStore,
  loader: new StoreProcedures.LoaderStore,
  rrss: new StoreProcedures.RRSSStore,
  home: new StoreProcedures.HomeStore,
  ourServices: new StoreProcedures.OurServicesStore,
  history: new StoreProcedures.HistoryStore,
  workProcess: new StoreProcedures.WorkProcessStore,
  contact: new StoreProcedures.ContactStore,
  services: new StoreProcedures.ServiceStore,
  approach: new StoreProcedures.ApproachStore,
  clients: new StoreProcedures.ClientsStore,
  us: new StoreProcedures.UsStore,
  templates: new StoreProcedures.TemplatesStore,
  menu: new StoreProcedures.MenuStore,
  privacy: new StoreProcedures.PrivacyStore,
  configuration: new StoreProcedures.ConfigurationStore,
}