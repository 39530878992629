export const addJsToElement = (renderer: any, src: string, ...args: any): HTMLScriptElement => {
    removeJsToElement(renderer, src);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    if (args.length) {
        args.map((item: any) => {
            script.setAttribute(item.name, item.value)
        })
    }
    renderer.appendChild(document.body, script);
    return script;
}

export const removeJsToElement = (renderer: any, src: string): void => {
    const script: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName('script');
    Array.from(script).forEach(element => {
        if (element.src.indexOf(src) != -1) {
            element.remove();
        }
    });
}

export const removeElementHTML = (renderer: any, _class: string): void => {
    const script: HTMLCollectionOf<HTMLAnchorElement> = document.getElementsByTagName('a');
    Array.from(script).forEach(element => {
        let exist = 0;
        element.classList.forEach(item => {
            if (_class.indexOf(item) != -1) {
                exist += 1;
            }
        });

        if (exist == _class.split(' ').length) {
            element.remove();
        }
    });
}