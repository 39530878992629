import Store from "../core/core"

// interface ITest {
//     data?: string,
// }

export class MenuStore extends Store {
  protected override nameState: string = 'menu'
  protected override contentName: string = '/v1/menu'
  protected override initialState: any = {}

  addContent(data: any) {
    return {
      ...this,
      newState: data
    }
  }
}