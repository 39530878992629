import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GenericObservableService, LoaderObservableService, ModalObservableService } from './_core/observables';
import { StoreSessionService } from './_core/store/core';
import { StoreModule } from './_core/store/storeModule';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscription: Subscription = new Subscription
  content: any = null
  modalData: any = {
    data: {},
    options: <any>{
      centered: true,
      scrollable: true,
      backdrop: 'static'
      // size: ModalSize.SM,
      // backdropClass: 'light-blue-backdrop',
      // fullscreen: ModalSize.SM,
    }
  }

  @ViewChild('templateModalErrorInterceptor', { static: false }) templateModalErrorInterceptor: ElementRef | null = null
  @ViewChild('templateLoader', { static: false }) templateLoader: ElementRef | null = null

  constructor(
    @Inject(PLATFORM_ID) private platformid: any,
    private modalService: NgbModal,
    private modalObservable: ModalObservableService,
    private loaderObservable: LoaderObservableService,
    private genericObservable: GenericObservableService,
    private sessionStorageSevice: StoreSessionService,
  ) {
    if (isPlatformBrowser(this.platformid)) {
      this.loaderObservable.loaderChange(true)
    }
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformid)) {
      this.loaderObservable.loaderChange(false)
    }
  }
  // --
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe
  }
  // --
  ngOnInit(): void {
    // if (isPlatformBrowser(this.platformid)) {
    this.onObservables()
    // }
  }
  // --
  onObservables() {
    Object.values(StoreModule).map((x: any) => {
      if (x.name != 'loader') {
        this.subscription.add(
          this.genericObservable.generic$
            .subscribe(
              (observer: any) => {
                if (observer && observer?.method == 'getContentCMS' && observer.content && observer?.module == x.name) {
                  this.sessionStorageSevice.setStore(x.addContent(observer.content))
                }
              }
            )
        )
      }
    })
    this.subscription.add(
      this.modalObservable.modalErrorInterceptor$
        .subscribe(
          (value: any) => {
            if (isPlatformBrowser(this.platformid)) {
              this.modalData.data = {}
              if (value && typeof value == 'object' && Object.values(value).length) {
                this.modalData.data = value
                this.modalService.open(this.templateModalErrorInterceptor, this.modalData.options)
              }
            }
          }
        )
    )
  }
}