import { clone } from "./convert"

class AStore {
  protected nameState: string = ''
  protected initialState: any = {}
  protected counterServiceCalled: number = 0
  protected contentName: string = ''
  public persistance: boolean = false // reset values when reload browser
  public contentInit: boolean = false
  public contentLoading: boolean = false
  // --
  setCounter(value: number) {
    this.counterServiceCalled += value
  }
  // --
  get counter() {
    return this.counterServiceCalled
  }
  // --
  get state(): any {
    return {
      ...this,
      initialState: clone(this.initialState)
    }
  }
  get name(): string {
    return this.nameState
  }
  // --
  setContent(name: string) {
    this.contentName = name
  }
  get content(): string {
    return this.contentName
  }
  // --
  setLoading(value: boolean) {
    this.contentLoading = value
    if (!value) {
    }
  }
  // --
  get loading(): boolean {
    return this.contentLoading
  }
}

export default class Store extends AStore {
  protected override nameState: string = ''
  protected override initialState: any = {}
  protected override counterServiceCalled: number = 0
  protected override contentName: string = ''
  public override persistance = false
  public override contentInit: boolean = true
}