import { isExternalLink, slugify } from "./convert"

const formatResponseContentOfStore = (args: any, content: any): any => {
    if (args && args.length) {
        args.map((key: string) => {
            let Key = key.split(':').shift()
            let params = key.split(':').slice(1)
            try {
                switch (Key) {
                    case 'array':
                        if (content) {
                            if (!Array.isArray(content)) {
                                if (typeof content == 'object' && Object.keys(content).map((x: any) => !isNaN(x)).filter((x: Boolean) => x).length) {
                                    content = Object.values(content)
                                }
                                else {
                                    content = content ? [content] : []
                                }
                            }
                        }
                        else {
                            content = []
                        }
                        break;

                    case 'join':
                        if (Array.isArray(content)) {
                            content = content.join(params[0] || ',')
                        }
                        break;

                    case 'split':
                        if (!Array.isArray(content)) {
                            content = content.split(params[0] || '')
                        }
                        break;

                    case 'uppercase':
                        content = content.toUpperCase()
                        break;

                    case 'lowercase':
                        content = content.toLowerCase()
                        break;

                    case 'titlecase':
                        content = ((str: string) => {
                            return str.toLowerCase().split(' ').map((word: string) => {
                                return `${word.charAt(0).toUpperCase()}${word.slice(1)}`
                            }).join(' ')
                        })(content)
                        break;

                    case 'trim':
                        content = content.trim()
                        break;

                    case 'slugify':
                        content = slugify(content, '-')
                        break;

                    case 'first':
                        if (Array.isArray(content)) {
                            content = content.shift() || ''
                        }
                        else if (typeof content == 'object' && Object.keys(content).map((x: any) => !isNaN(x)).filter((x: Boolean) => x).length) {
                            content = Object.values(content)[0] || ''
                        }
                        break;

                    case 'url':
                        switch (params[0]) {
                            case 'external':
                                if (!isExternalLink(content)) {
                                    content = `http://${content}`
                                }
                                break;

                            default:
                                break;
                        }
                        break;

                    case 'map':
                        if (params.length) {
                            content = content.map((x: any) => {
                                if (params.length > 1) {
                                    let d: any = {}
                                    params.map((y: string) => {
                                        d[y] = x[y] || ''
                                    })
                                    return d
                                }
                                else {
                                    return x[params[0]]
                                }
                            }).filter((x: any) => x)
                        }
                        else {
                            content = content.map((x: any) => x).filter((x: any) => x)
                        }
                        break;

                    default:
                        break;
                }
            } catch (error) {
                console.error(`No se puede formatear el contenido ${content} a ${key}`)
            }
        })
    }
    return content
}
// --
export const getContentOfStore = (content: any, property?: string, ...args: any): any => {
    let isValid = false
    let value: any = null
    if (property) {
        value = property.split('.')
        return value.map((prop: string, i: number) => {
            if ((i == value.length - 1)) {
                if (content && content[prop] != null && content[prop] != undefined) {
                    switch (typeof content[prop]) {
                        case 'object':
                            if (Array.isArray(content[prop]) && Boolean(content[prop].length)) {
                                isValid = true
                            }
                            if (!Array.isArray(content[prop]) && Boolean(Object.keys(content[prop]).length)) {
                                isValid = true
                            }
                            break;

                        default:
                            if (Boolean(content[prop].toString().trim().length)) {
                                isValid = true
                            }
                            break;
                    }
                }
                if (isValid) {
                    return formatResponseContentOfStore(args, content[prop])
                }
            }
            else {
                value.shift()
                if (Boolean(content) && Boolean(content[prop])) {
                    return getContentOfStore(content[prop], value.join('.'), ...args)
                }
                return formatResponseContentOfStore(args, null)
            }
        }).find((x: any) => x) || null
    }
    if (!property && content) {
        switch (typeof content) {
            case 'object':
                if (Array.isArray(content) && Boolean(content.length)) {
                    isValid = true
                }
                if (!Array.isArray(content) && Boolean(Object.keys(content).length)) {
                    isValid = true
                }
                break;

            default:
                if (Boolean(content.toString().trim().length)) {
                    isValid = true
                }
                break;
        }
        if (isValid) {
            return formatResponseContentOfStore(args, content)
        }
        switch (typeof content) {
            case 'object':
                if (Array.isArray(content) && Boolean(content.length)) {
                    value = []
                }
                if (!Array.isArray(content) && Boolean(Object.keys(content).length)) {
                    value = {}
                }
                break;

            default:
                if (Boolean(content.toString().trim().length)) {
                    value = null
                }
                break;
        }
        return formatResponseContentOfStore(args, value)
    }
}