import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderObservableService } from 'src/app/_core/observables';
import { StoreSessionService } from 'src/app/_core/store/core';
import { StoreModule } from 'src/app/_core/store/storeModule';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription

  loader: any = {
    counter: 0
  }

  constructor(
    @Inject(PLATFORM_ID) private platformid: any,
    private loaderObservable: LoaderObservableService,
    private sessionStorageSevice: StoreSessionService,
  ) { }
  // --
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe
  }
  // --
  ngOnInit(): void {
    this.subscription.add(
      this.loaderObservable.loader$
        .subscribe(
          (value: any) => {
            this.sessionStorageSevice.setStore(StoreModule.loader.addContent(value ? 1 : -1))
          }
        )
    )
    this.subscription.add(
      this.sessionStorageSevice.getStore(StoreModule.loader)
        .subscribe(
          (store: any) => {
            if (isPlatformBrowser(this.platformid)) {
              if (store?.loader) {
                this.loader.counter = store.loader.counter
                setTimeout(() => {
                  if (this.loader.counter <= 0) {
                    this.loader.counter = 0
                  }
                }, 200)
              }
            }
          }
        )
    )
  }
  // --
  get showComponent() {
    return this.loader.counter
  }
}
