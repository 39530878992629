import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { decrypt, encrypt, JSONTryer } from './';

@Injectable({ providedIn: 'root' })
export class MemoryStorage implements Storage {
  private data: { [key: string]: string } = {};

  get length(): number {
    return Object.keys(this.data).length;
  }

  clear(): void {
    this.data = {};
  }

  getItem(key: string): string | null {
    if (!key) {
      return null
    }
    let data: string = this.data[key] || ''
    if (environment.production) {
      data = this.data[encrypt(key)] || ''
      data = decrypt(data)
    }
    return Boolean(data) ? JSONTryer(data) : null
  }

  key(index: number): string | null {
    const keys = Object.keys(this.data);
    return index >= 0 && keys.length < index ? keys[index] : null;
  }

  removeItem(key: string | string[]): void {
    if (environment.production) {
      if (typeof key == 'string') {
        delete this.data[encrypt(key)]
      }
      else {
        key.map(item => {
          delete this.data[encrypt(item)]
        })
      }
    } else {
      if (typeof key == 'string') {
        delete this.data[key]
      }
      else {
        key.map(item => {
          delete this.data[item]
        })
      }
    }
  }

  setItem(key: string, value: string): void {
    if (environment.production) {
      this.data[encrypt(key)] = encrypt(JSON.stringify(value))
    } else {
      this.data[key] = JSON.stringify(value)
    }
  }
}