import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class dynamicObservableService {

  constructor() { }

  // --- Loader
  private form = new Subject<any>();
  public form$ = this.form.asObservable();
  public formChange(value: any): void {
    this.form.next(value);
  }
}
