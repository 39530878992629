import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { JSONTryer } from '../functions';
import { LoaderObservableService, ModalObservableService } from '../observables';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(
    private modalObservable: ModalObservableService,
    private loaderObservable: LoaderObservableService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // -
    let modal: any = true
    if (request.headers.has('modal')) {
      modal = JSONTryer(request.headers.get('modal'))
      request = request.clone({
        headers: request.headers.delete('modal')
      })
    }

    let loader: boolean = true
    if (request.headers.has('loader')) {
      request = request.clone({
        headers: request.headers.delete('loader')
      })
    }

    // --
    return next.handle(request)
      .pipe(
        map((rs: any) => {
          if (rs.hasOwnProperty('body')) {
            if (loader) {
              this.loaderObservable.loaderChange(false)
              // setTimeout(() => {
              // }, 300)
            }
            return rs
          }
        }),
        catchError((error: HttpErrorResponse) => {
          let errorMsg = ''
          if (error.error instanceof ErrorEvent) {
            console.error('This is client side error')
            errorMsg = `Error: ${error.error.message}`
          } else {
            console.error('This is server side error')
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`
            if (error?.error?.error) {
              errorMsg += `,  Error: ${error.error.error}`
            }
          }
          if (modal) {
            this.modalObservable.modalErrorInterceptorChange({
              title: 'Error de repuesta del servicio',
              body: errorMsg
            })
          }
          if (loader) {
            setTimeout(() => {
              this.loaderObservable.loaderChange(false)
            }, 300)
          }
          return throwError(errorMsg)
          // return throwError(() => new Error(errorMsg))
        })
      )
    // return next.handle(request)
  }
}
