import Store from "../core/core"

// interface ITest {
//     data?: string,
// }

export class TemplatesStore extends Store {
  protected override nameState: string = 'templates'
  protected override contentName: string = '/catalogo_formularios_plantillas'
  protected override initialState: any = {}

  addContent(data: any) {
    return {
      ...this,
      newState: data
    }
  }
}