import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalObservableService {

  constructor() { }

  // --- Modal
  private modal = new Subject<any>();
  public modal$ = this.modal.asObservable();
  public modalChange(value: any = null): void {
    this.modal.next(value);
  }

  // --- Modal
  private modalErrorInterceptor = new Subject<any>();
  public modalErrorInterceptor$ = this.modalErrorInterceptor.asObservable();
  public modalErrorInterceptorChange(value: any = null): void {
    this.modalErrorInterceptor.next(value);
  }
}
