import { ApiService } from "./api.service";
import { CMSContentService } from "./cms-content.service";
import { CMSService } from "./cms.service";
import { InterceptorService } from "./interceptor.service";
import { SettingsService } from "./settings.service";

export * from "./api.service";
export * from "./cms-content.service";
export * from "./cms.service";
export * from "./interceptor.service";
export * from "./settings.service";

export const CoreServicesImports = [
  SettingsService,
  ApiService,
  InterceptorService,
  CMSContentService,
  CMSService
]