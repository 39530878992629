<ng-container *ngFor="let item of elements">
    <ng-container [ngSwitch]="item.type">
        <ng-container *ngSwitchCase="styles.Lineal">
            <div class="mb-2 shadow-{{ item.type }}" [style.width.px]="item.width" [style.height.px]="item.height">
                Texto del shadow
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="styles.Circle">
            <div class="mb-2 shadow-{{ item.type }}" [style.width.px]="item.width" [style.height.px]="item.height">
                Texto del shadow
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="styles.Square">
            <div class="mb-2 shadow-{{ item.type }}" [style.width.px]="item.width" [style.height.px]="item.height">
                Texto del shadow
            </div>
        </ng-container>
    </ng-container>
</ng-container>