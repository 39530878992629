import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { clone, StoreObservable } from '../';
import { StoreModule } from '../../storeModule';
import { MemoryStorage } from '../storageMemory';

@Injectable({ providedIn: 'root' })
export class StoreMemoryService {
  storageName: string = 'ls_store_procedure'
  static storeCurrent: any

  constructor(
    private storeObservable: StoreObservable,
    private storage: MemoryStorage,
  ) {
    StoreMemoryService.storeCurrent = this.storage.getItem(this.storageName) || {}
  }
  // --
  setStore(method: any) {
    let module: string = method.nameState
    let store: any = StoreMemoryService.storeCurrent
    if (!Boolean(store[module])) {
      store[module] = {}
    }
    let data: any = method.initialState
    data = { ...data, ...store[module] }
    if (method?.newState) {
      data = { ...data, ...method.newState }
    }
    let valueStore: any = {}
    valueStore = clone({ ...store, [module]: { ...data } })
    this.storeObservable.changeStore({
      update: [module],
      value: valueStore
    })
  }
  // --
  removeStore(module: string | string[]) {
    if (!Array.isArray(module)) {
      module = [module]
    }
    let store: any = {}
    let update: string[] = []
    store = StoreMemoryService.storeCurrent
    if (Object.keys(store)) {
      module.map(x => {
        if (store[x]) {
          store[x] = StoreModule[x].state.initialState
          update.push(x)
        }
      })
    }
    // }
    let valueStore = clone({ ...store })
    this.storeObservable.changeStore({
      update: update,
      value: valueStore
    })
  }
  // ---
  getStoreObservable(module: string): Observable<any> {
    return this.storeObservable.Store$
      .pipe(
        map((store: any) => {
          let value: any = {}
          if (store?.update && Boolean(store.update.length)) {
            store?.update.map((x: string) => {
              if (x == module) {
                value = {
                  [x]: clone(store.value[x])
                }
                if (!Boolean(StoreMemoryService.storeCurrent[module])) {
                  StoreMemoryService.storeCurrent[module] = {}
                }
                this.storage.setItem(this.storageName, { ...StoreMemoryService.storeCurrent, [module]: { ...StoreMemoryService.storeCurrent[module], ...store.value[x] } })
                StoreMemoryService.storeCurrent = { ...StoreMemoryService.storeCurrent, [module]: { ...StoreMemoryService.storeCurrent[module], ...store.value[x] } }
              }
            })
          }
          return clone(value)
        })
      )
  }
  getStore(module: string): Observable<any> {
    setTimeout(() => {
      let store: any = StoreMemoryService.storeCurrent
      if (store[module]) {
        this.storeObservable.changeStore({
          update: [module],
          value: clone({ ...store, [module]: { ...StoreModule[module].state.initialState, ...store[module] } })
        })
      }
      else {
        store = this.setStore(StoreModule[module].state)
      }
    })
    return this.getStoreObservable(module)
  }
}
