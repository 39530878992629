import Store from "../core/core"

// interface ITest {
//     data?: string,
// }

export class WorkProcessStore extends Store {
  protected override nameState: string = 'workProcess'
  protected override contentName: string = '/proceso_de_trabajo'
  protected override initialState: any = {}

  addContent(data: any) {
    return {
      ...this,
      newState: data
    }
  }
}