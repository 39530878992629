export const settings: any = {
  ws: <any>{
    base: <any>{
      prod: <any>{
        baseUrlCms: 'http://34.134.80.207:7000/cloudcms',
        files: <any>{
          old: ['http://localhost/imagenes', 'https://storage.googleapis.com/abargon'],
          new: 'https://storage.googleapis.com/abargon',
        },
      },
      dev: <any>{
        baseUrlCms: 'http://34.134.80.207:7000/cloudcms',
        files: <any>{
          old: ['http://localhost/imagenes', 'https://storage.googleapis.com/abargon'],
          new: 'https://storage.googleapis.com/abargon',
        },
      },
    },
    res: <any>{
      cms: <any>{
        signin: '/v1/access/signin',
        contentType: '/content/v1/contenttype',
        contentCMS: '/content/v1/content',
        email: '/v1/Email',
      }
    },
  }
};
