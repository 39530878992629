import { StoreSessionService } from "../core"
import Store from "../core/core"

// interface ITest {
//     data?: string,
// }

export class LoaderStore extends Store {
  protected override nameState: string = 'loader'
  protected override contentName: string = 'loader'
  protected override initialState: any = {
    counter: 0
  }

  // --
  get current() {
    return StoreSessionService.storeCurrent?.loader || 0
  }
  // --
  addContent(data: any) {
    let counter = this.current?.counter || 0
    counter = counter + data
    if (counter < 0) {
      counter = 0
    }
    return {
      ...this,
      newState: {
        counter: counter
      }
    }
  }
}