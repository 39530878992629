import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderObservableService {

  constructor() { }

  // --- Loader
  private loader = new Subject<boolean>();
  public loader$ = this.loader.asObservable();
  public loaderChange(value: boolean): void {
    this.loader.next(value);
  }
}
