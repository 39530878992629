import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { clone, getArray, getContentOfStore, getObject, slugify } from 'src/app/_core/functions';
import { dynamicObservableService } from 'src/app/_core/observables';
import { CMSContentService, CMSService } from 'src/app/_core/services';
import { StoreSessionService } from 'src/app/_core/store/core';
import { StoreModule } from 'src/app/_core/store/storeModule';
import { v1 as uuidv1 } from 'uuid';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.less'],
  providers: [
    DatePipe
  ]
})
export class FormsComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscription: Subscription = new Subscription

  getContentOfStore = getContentOfStore

  @ViewChild('modalForm', { static: false }) modalForm: ElementRef | null = null
  @ViewChild('modalConfirm', { static: false }) modalConfirm: ElementRef | null = null
  modalData: any = {
    modal: <any | null>null,
    options: <any>{
      centered: true,
      scrollable: true,
      backdrop: 'static',
      size: 'md',
      // size: 'xl',
      // backdropClass: 'light-blue-backdrop',
      // fullscreen: ModalSize.SM,
    }
  }

  StoreModule = StoreModule.templates
  contentTemplate: any[] = []
  formHistory: any[] = []
  forms: any = {}
  content: any = {}
  show: boolean = false

  emailData: any = {
    to: <string>'',
    subject: <string>'',
    body: <string>'',
  }

  token: any = null

  structureToSend: any = {
    contentTranslaction: "",
    content_type: {
      uuid: ""
    },
    language: {
      id: 1
    },
    status: 1,
    system_name: "",
    title: "",
    uuid: "",
    isUpdated: false,
    values: <{
      field_system_name: string,
      field_value: string,
      field_label: string,
      field_position: string
    }[]>[]
  }

  messageForm: any = {
    message: '',
    type: ''
  }

  pressButton: boolean = false

  constructor(
    private dynamicObservable: dynamicObservableService,
    private modalService: NgbModal,
    private storeSevice: StoreSessionService,
    private cmsContentService: CMSContentService,
    private cmsService: CMSService,
    private datePipe: DatePipe,
  ) { }
  // --
  ngAfterViewInit(): void {
    // max-height: 515px;
    // min-height: 100px;
    // class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md"
  }
  // --
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe
  }
  // --
  ngOnInit(): void {
    this.subscription.add(
      this.dynamicObservable.form$
        .subscribe(
          (value: any) => {
            if (value?.content && Object.keys(value.content).length) {
              this.contentTemplateCMS()
              this.onCreateForm(value.content)
            }
          }
        )
    )
  }
  // --
  onCreateForm(formulario: any) {
    this.forms = {}
    this.show = false
    this.pressButton = false
    this.messageForm = {
      message: '',
      type: ''
    }
    this.emailData = {
      to: '',
      subject: '',
      body: '',
    }
    this.addForm(formulario)
  }
  // --
  get getShowComponent() {
    return this.show
  }
  // --
  get getIDForm(): boolean {
    return Boolean(this.forms[getContentOfStore(this.content, 'system_name')].controls.id.value > 1)
  }
  // --
  get getPressButton(): any {
    return this.pressButton || null
  }
  // --
  get notExistCancelButton(): any {
    let notExist = true
    getContentOfStore(this.content, 'botones', 'array').map((x: any) => {
      if (x['acciónDelBoton'] == 'cancel') {
        notExist = false
      }
    })
    return notExist
  }
  // --
  contentTemplateCMS() {
    this.subscription.add(
      this.storeSevice.getStore(this.StoreModule)
        .subscribe(
          (store: any) => {
            if (store[this.StoreModule.name] && Object.keys(store[this.StoreModule.name]).length) {
              this.contentTemplate = getContentOfStore(store[this.StoreModule.name], '', 'array')
              this.StoreModule.contentInit = false
              this.StoreModule.setLoading(false)
            }

            if (this.StoreModule.contentInit) {
              this.StoreModule.contentInit = false
              this.cmsContentService.getContentCMS(this.StoreModule)
            }
          }
        )
    )
  }
  // --
  getFormControls(contentParent: any, contentChild: any) {
    return this.forms[getContentOfStore(contentParent, 'system_name')].controls[getContentOfStore(contentChild, 'pregunta', 'trim', 'slugify')]
  }
  // --
  setValidators(properties: any): any[] {
    let validators: Validators[] = []
    if (properties.tipoDePregunta == 'email') {
      validators.push(Validators.email)
    }
    if (properties.hasOwnProperty('requerido') && Boolean(properties.requerido)) {
      validators.push(Validators.required)
    }
    if (properties.hasOwnProperty('fechaMinima') && Boolean(properties.fechaMinima)) {
      validators.push(Validators.min(properties.fechaMinima))
    }
    if (properties.hasOwnProperty('fechaMaxima') && Boolean(properties.fechaMaxima)) {
      validators.push(Validators.max(properties.fechaMaxima))
    }
    if (properties.hasOwnProperty('tamanioMinimo') && Boolean(properties.tamanioMinimo)) {
      validators.push(Validators.minLength(properties.tamanioMinimo))
    }
    if (properties.hasOwnProperty('tamanioMaximo') && Boolean(properties.tamanioMaximo)) {
      validators.push(Validators.maxLength(properties.tamanioMaximo))
    }
    if (properties.hasOwnProperty('valorMinimo') && Boolean(properties.valorMinimo)) {
      validators.push(Validators.min(properties.valorMinimo))
    }
    if (properties.hasOwnProperty('valorMaximo') && Boolean(properties.valorMaximo)) {
      validators.push(Validators.max(properties.valorMaximo))
    }
    if (properties.tipoDePregunta != 'file') {
      if (properties.hasOwnProperty('validacion') && Boolean(properties.validacion)) {
        validators.push(Validators.pattern(new RegExp(properties.validacion)))
      }
    }
    return validators
  }
  // --
  addForm(formulario: any, position?: number) {
    if (getContentOfStore(formulario, 'system_name')) {
      if (position != null && position != undefined && Boolean(this.formHistory[position])) {
        // Si existen formulario
        this.content = clone(this.formHistory[position])
      }
      else {
        // No existe formulario
        this.content = clone(formulario)
        this.formHistory.push(clone(this.content))
        // --
        this.forms[getContentOfStore(this.content, 'system_name')] = []
        let data: FormGroup = new FormGroup({})
        getContentOfStore(this.content, 'campos', 'array').map((property: any) => {
          if (property && typeof property == 'object' && !Array.isArray(property) && Object.keys(property).length) {
            data.addControl(slugify(property.pregunta.trim()), new FormControl('', this.setValidators(property)))
          }
        })
        if (Object.keys(data.controls).length) {
          data.addControl('system_title', new FormControl(getContentOfStore(this.content, 'titulo')))
          data.addControl('system_name', new FormControl(getContentOfStore(this.content, 'system_name')))
          data.addControl('uuid', new FormControl(getContentOfStore(this.content, 'uuid')))
          data.addControl('id', new FormControl(Object.keys(this.forms).length))
          this.forms[getContentOfStore(this.content, 'system_name')] = data
        }
      }
      this.show = true
      setTimeout(() => {
        this.modalData.modal = this.modalService.open(this.modalForm, this.modalData.options)
      }, 200)
    }
  }
  // --
  componentSupportStyleFloating(type: string): boolean {
    let supportComponent: string[] = [
      "text",
      "number",
      "textarea",
      "email",
      "url",
      "time",
      "date",
      "datetime",
      "datetime-range",
      "color",
      "file",
      "range",
      "select",
      // 'radio',
      // 'checkbox',
      // 'boolean',
    ]
    return supportComponent.includes(type)
  }
  // --
  isValidForm(key: any) {
    if (this.forms[key].invalid) {
      this.forms[key].markAllAsTouched()
      return false
    }
    return true
  }
  // --
  onNextForm(formulario: any) {
    if (this.isValidForm(getContentOfStore(this.content, 'system_name'))) {
      if (this.modalData.modal) {
        this.modalData.modal.close()
      }
      this.show = false
      setTimeout(() => {
        this.addForm(formulario, this.forms[getContentOfStore(this.content, 'system_name')].controls.id.value)
      }, 100)
    }
  }
  // --
  onBackForm(formulario: any) {
    if (this.isValidForm(getContentOfStore(this.content, 'system_name'))) {
      if (this.modalData.modal) {
        this.modalData.modal.close()
      }
      this.show = false
      setTimeout(() => {
        this.addForm(formulario, this.forms[getContentOfStore(this.content, 'system_name')].controls.id.value - 2)
      }, 100)
    }
  }
  // --
  onSaveForm() {
    let isValid = Boolean(Object.keys(this.forms).length)
    if (Object.keys(this.forms).length) {
      Object.keys(this.forms).map((key: string) => {
        if (!this.isValidForm(getContentOfStore(this.content, 'system_name'))) {
          this.forms[key].markAllAsTouched()
          isValid = false
        }
      })
    }
    if (isValid) {
      this.pressButton = true
      this.messageForm = {
        message: '',
        type: ''
      }
      this.emailData = {
        to: '',
        subject: '',
        body: '',
      }
      this.contentTemplate.map((template: any): any => {
        if (getContentOfStore(template, 'formularios', 'map:uuid').includes(this.getDataFormByID(1)?.uuid) && !Boolean(this.emailData.to)) {
          this.emailData = {
            to: getContentOfStore(template, 'correos', 'array', 'join'),
            subject: getContentOfStore(template, 'asunto', 'first'),
            body: this.replaceDataToTemplate(getContentOfStore(template, 'plantilla_html', 'first')),
          }
          return false
        }
      })
      // --
      if (Boolean(this.emailData.to)) {
        this.sendEmail(this.emailData)
      }
      this.saveOnCMS(this.orderForID(Object.values(this.forms).map((x: any) => x.value)))
      // else {
      //   this.pressButton = false
      // }
    }
  }
  // --
  orderForID(forms: any[]): any {
    let f: any[] = []
    forms.map((x: any) => {
      f[x.id] = x
    })
    return f.filter((x: any) => x)
  }
  // --
  getDataFormByID(id: number): any {
    let d: any = Object.values(this.forms).find((f: any) => f.value.id == id)
    if (d) {
      d = d?.value
    }
    return d || {}
  }
  // --
  replaceDataToTemplate(template: string) {
    let t = clone(template)
    Object.values(this.forms).map((f: any) => {
      Object.keys(f.value).map((v: any) => {
        if (t.includes(`{${v}}`)) {
          let r = new RegExp(`{${v}}`, 'g')
          t = t.replace(r, f.value[v])
        }
      })
    })
    return t
  }
  // --
  async sendEmail(data: any) {
    const [rs, error] = await this.cmsService.sendEmail(data, { modal: false })
    if (error) {
      console.error(error)
    }
  }
  // --
  async saveOnCMS(forms: any[]): Promise<any> {
    let [rs, error] = await this.cmsService.postSession()
    if (error) {
      console.error(error)
      this.pressButton = false
      this.messageForm.type = 'danger'
      this.messageForm.message = 'Ocurrio un error en el servicio, por favor intente mas tarde.'
      return false
    }
    this.token = getObject(rs)?.accessToken || null
    if (!Boolean(this.token)) {
      console.error('No existe token')
      return false
    }
    [rs, error] = await this.cmsService.getContentType(this.token.accessToken)
    if (error) {
      console.error(error)
      this.pressButton = false
      this.messageForm.type = 'danger'
      this.messageForm.message = 'Ocurrio un error en el servicio, por favor intente mas tarde.'
      return false
    }
    let d: any = getArray(rs).find((x: any) => x.system_name == 'formulario_contactos')
    if (Boolean(d)) {
      let uuid = uuidv1()
      this.structureToSend.content_type.uuid = d.uuid
      this.structureToSend.system_name = `${new Date().getTime()}-${uuid}`
      this.structureToSend.title = this.structureToSend.system_name
      this.structureToSend.uuid = uuid
      this.structureToSend.values = [{
        field_position: "",
        field_label: "",
        field_system_name: 'formulario',
        field_value: forms.map((x: any) => {
          return { title: x.system_title, system_name: x.system_name, uuid: x.uuid }
        }),
      },
      {
        field_position: "",
        field_label: "",
        field_system_name: 'datos',
        field_value: forms,
      },
      {
        field_position: "",
        field_label: "",
        field_system_name: 'creado',
        field_value: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'GMT-6'),
      },
      {
        field_position: "",
        field_label: "",
        field_system_name: 'estatus',
        field_value: '',
      },
      {
        field_position: "",
        field_label: "",
        field_system_name: 'atencion_quien',
        field_value: '',
      },
      {
        field_position: "",
        field_label: "",
        field_system_name: 'atencion_fecha',
        field_value: '',
      }]
      const [rs, error] = await this.cmsService.postContent(this.token.accessToken, this.structureToSend)
      if (error) {
        console.error(error)
        this.pressButton = false
        this.messageForm.type = 'danger'
        this.messageForm.message = 'Ocurrio un error en el servicio, por favor intente mas tarde.'
        return false
      }
      this.messageForm.type = 'success'
      this.messageForm.message = 'Se guardó con éxito la información.'
      if (this.modalData.modal) {
        this.modalData.modal.close()
      }
      setTimeout(() => {
        this.modalData.modal = this.modalService.open(this.modalConfirm, this.modalData.options)
      }, 200)
    }
    else {
      this.pressButton = false
      this.messageForm.type = 'danger'
      this.messageForm.message = 'Ocurrio un error en el servicio, por favor intente mas tarde.'
      return false
    }
  }
  // --
  onChangeOptions(key: string, event: any, control: FormControl) {
    control.markAsTouched()
    control.markAsDirty()
    let val: string[] = control.value.split(',')
    if (Boolean(val.length)) {
      val = control.value.split(',').filter((x: any) => x)
      switch (key) {
        case 'radio':
          val = [event.target.value]
          break;
        case 'checkbox':
          if (event.target.checked) {
            val.push(event.target.value)
          }
          else {
            val = control.value.split(',').filter((x: any) => x != event.target.value && x)
          }
          break;

        default:
          break;
      }
      control.setValue(val.join(','))
    }
  }
}
