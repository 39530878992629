import { decrypt, encrypt } from "./bcrypt";
import { getContentOfStore } from "./contentStore";
import { clone, generatePassword, getBase64FromUrl, getBrowserLanguage, JSONTryer, removeHTMLElementForText, replaceSpecialChars, replaceText, slugify } from "./convert";
import { getArray, getFilterContentCMS, getObject, JSONParse, makeHeaders } from "./http";
import { addJsToElement, removeElementHTML, removeJsToElement } from "./importJS";

export * from "./bcrypt";
export * from "./contentStore";
export * from "./convert";
export * from "./http";
export * from "./importJS";

export const CoreConfigImports = [
  encrypt,
  decrypt,

  JSONTryer,
  replaceText,
  slugify,
  replaceSpecialChars,
  getBase64FromUrl,
  clone,
  getBrowserLanguage,
  generatePassword,
  removeHTMLElementForText,

  getArray,
  getObject,
  getFilterContentCMS,
  makeHeaders,
  JSONParse,

  getContentOfStore,

  addJsToElement,
  removeJsToElement,
  removeElementHTML,
]