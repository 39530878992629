<ng-container *ngIf="getShowComponent">
    <ng-container *ngIf="getContentOfStore(content, 'system_name')">
        <ng-template #modalForm let-modal>
            <app-modal class="modal-content">
                <span title>
                    {{ getContentOfStore(content, 'titulo') }}
                </span>

                <button buttonHeader type="button" class="btn-close" aria-label="Close"
                    (click)="modal.close()"></button>

                <div body>
                    <form [formGroup]="forms[getContentOfStore(content, 'system_name')]"
                        [attr.id]="getContentOfStore(content, 'system_name')" class="mb-5">
                        <ng-container *ngFor="let item of getContentOfStore(content, 'campos', 'array')">

                            <ng-container
                                *ngIf="componentSupportStyleFloating(getContentOfStore(item, 'tipoDePregunta')); else notFloating">

                                <ng-container [ngSwitch]="getContentOfStore(item, 'tipoDePregunta')">
                                    <div class="form-floating" style="margin-bottom: 2.1rem;">

                                        <ng-container *ngSwitchCase="'link'">
                                            <input type="url" class="form-control"
                                                [ngClass]="{'is-invalid': getFormControls(content, item).errors && ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                [formControlName]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.placeholder]="getContentOfStore(item, 'placeholder', 'first')"
                                                [required]="getContentOfStore(item, 'requerido') || null"
                                                [attr.minlength]="getContentOfStore(item, 'tamanioMinimo')"
                                                [attr.maxlength]="getContentOfStore(item, 'tamanioMaximo')">
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'phone'">
                                            <input type="tel" class="form-control"
                                                [ngClass]="{'is-invalid': getFormControls(content, item).errors && ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                [formControlName]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.placeholder]="getContentOfStore(item, 'placeholder', 'first')"
                                                [required]="getContentOfStore(item, 'requerido') || null"
                                                [attr.minlength]="getContentOfStore(item, 'tamanioMinimo')"
                                                [attr.maxlength]="getContentOfStore(item, 'tamanioMaximo')">
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'time'">
                                            <input type="time" class="form-control"
                                                [ngClass]="{'is-invalid': getFormControls(content, item).errors && ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                [formControlName]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.placeholder]="getContentOfStore(item, 'placeholder', 'first')"
                                                [required]="getContentOfStore(item, 'requerido') || null"
                                                [min]="getContentOfStore(item, 'fechaMinima')"
                                                [max]="getContentOfStore(item, 'fechaMaxima')">
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'date'">
                                            <input type="date" class="form-control"
                                                [ngClass]="{'is-invalid': getFormControls(content, item).errors && ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                [formControlName]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.placeholder]="getContentOfStore(item, 'placeholder', 'first')"
                                                [required]="getContentOfStore(item, 'requerido') || null"
                                                [min]="getContentOfStore(item, 'fechaMinima')"
                                                [max]="getContentOfStore(item, 'fechaMaxima')">
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'datetime'">
                                            <input type="datetime-local" class="form-control"
                                                [ngClass]="{'is-invalid': getFormControls(content, item).errors && ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                [formControlName]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.placeholder]="getContentOfStore(item, 'placeholder', 'first')"
                                                [required]="getContentOfStore(item, 'requerido') || null"
                                                [min]="getContentOfStore(item, 'fechaMinima')"
                                                [max]="getContentOfStore(item, 'fechaMaxima')">
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'color'">
                                            <input type="color" class="form-control form-control-color"
                                                [formControlName]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.placeholder]="getContentOfStore(item, 'placeholder', 'first')"
                                                [required]="getContentOfStore(item, 'requerido') || null">
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'image'">
                                            <input class="form-control"
                                                [ngClass]="{'is-invalid': getFormControls(content, item).errors && ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                type="file"
                                                [formControlName]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.accept]="getContentOfStore(item, 'validacion')"
                                                style="padding-top: 2.1rem;">
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'file'">
                                            <input class="form-control"
                                                [ngClass]="{'is-invalid': getFormControls(content, item).errors && ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                type="file"
                                                [formControlName]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.accept]="getContentOfStore(item, 'validacion')"
                                                style="padding-top: 2.1rem;">
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'textarea'">
                                            <textarea class="form-control"
                                                [ngClass]="{'is-invalid': getFormControls(content, item).errors && ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                [formControlName]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.placeholder]="getContentOfStore(item, 'placeholder', 'first')"
                                                [required]="getContentOfStore(item, 'requerido') || null"
                                                [attr.minlength]="getContentOfStore(item, 'tamanioMinimo')"
                                                [attr.maxlength]="getContentOfStore(item, 'tamanioMaximo')"
                                                [style.height.px]="150"></textarea>
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'select'">
                                            <select class="form-select"
                                                [ngClass]="{'is-invalid': getFormControls(content, item).errors && ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                [formControlName]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')">
                                                <ng-container *ngIf="getContentOfStore(item, 'placeholder', 'first')">
                                                    <option value="">
                                                        {{ getContentOfStore(item, 'placeholder', 'first') }}
                                                    </option>
                                                </ng-container>
                                                <ng-container
                                                    *ngFor="let option of getContentOfStore(item, 'opciones', 'array')">
                                                    <option [value]="getContentOfStore(option, '', 'trim', 'slugify')">
                                                        {{ getContentOfStore(option) }}
                                                    </option>
                                                </ng-container>
                                            </select>
                                        </ng-container>

                                        <ng-container *ngSwitchDefault>
                                            <input [attr.type]="getContentOfStore(item, 'tipoDePregunta')"
                                                class="form-control"
                                                [ngClass]="{'is-invalid': getFormControls(content, item).errors && ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                [formControlName]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                [attr.placeholder]="getContentOfStore(item, 'placeholder', 'first')"
                                                [required]="getContentOfStore(item, 'requerido') || null"
                                                [attr.minlength]="getContentOfStore(item, 'tamanioMinimo')"
                                                [attr.maxlength]="getContentOfStore(item, 'tamanioMaximo')"
                                                [attr.min]="getContentOfStore(item, 'valorMinimo')"
                                                [attr.max]="getContentOfStore(item, 'valorMaximo')">
                                        </ng-container>

                                        <div class="float-start w-100 d-flex">
                                            <small class="flex-grow-1 text-danger">
                                                <ng-container
                                                    *ngIf="getFormControls(content, item).errors?.required &&
                                                        ( getFormControls(content, item).touched || getFormControls(content, item).dirty )">
                                                    Este campo es requerido
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="!getFormControls(content, item).errors?.required &&
                                                        getFormControls(content, item).errors?.email &&
                                                        ( getFormControls(content, item).touched || getFormControls(content, item).dirty )">
                                                    Este campo no es válido
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="!getFormControls(content, item).errors?.required &&
                                                        !getFormControls(content, item).errors?.email &&
                                                        getFormControls(content, item).errors?.pattern &&
                                                        ( getFormControls(content, item).touched || getFormControls(content, item).dirty )">
                                                    {{ getContentOfStore(item, 'validacionMensaje', 'first') }}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="!getFormControls(content, item).errors?.required &&
                                                        !getFormControls(content, item).errors?.email &&
                                                        !getFormControls(content, item).errors?.pattern &&
                                                        getFormControls(content, item).errors?.minlength &&
                                                        ( getFormControls(content, item).touched || getFormControls(content, item).dirty )">
                                                    Debe contener mínimo
                                                    {{ getContentOfStore(item, 'tamanioMinimo', 'first') }}
                                                    {{ getContentOfStore(item, 'tipoDePregunta') == 'number' ? 'dígitos'
                                                    : 'caracteres' }}
                                                    caracteres
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="!getFormControls(content, item).errors?.required &&
                                                        !getFormControls(content, item).errors?.email &&
                                                        !getFormControls(content, item).errors?.pattern &&
                                                        getFormControls(content, item).errors?.maxlength &&
                                                        ( getFormControls(content, item).touched || getFormControls(content, item).dirty )">
                                                    Debe contener máximo
                                                    {{ getContentOfStore(item, 'tamanioMaximo', 'first') }}
                                                    {{ getContentOfStore(item, 'tipoDePregunta') == 'number' ? 'dígitos'
                                                    : 'caracteres' }}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="!getFormControls(content, item).errors?.required &&
                                                        !getFormControls(content, item).errors?.email &&
                                                        !getFormControls(content, item).errors?.pattern &&
                                                        !getFormControls(content, item).errors?.minlength &&
                                                        getFormControls(content, item).errors?.min &&
                                                        ( getFormControls(content, item).touched || getFormControls(content, item).dirty )">
                                                    Valor mínimo {{ getContentOfStore(item, 'valorMinimo', 'first') }}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="!getFormControls(content, item).errors?.required &&
                                                        !getFormControls(content, item).errors?.email &&
                                                        !getFormControls(content, item).errors?.pattern &&
                                                        !getFormControls(content, item).errors?.maxlength &&
                                                        getFormControls(content, item).errors?.max &&
                                                        ( getFormControls(content, item).touched || getFormControls(content, item).dirty )">
                                                    Valor máximo {{ getContentOfStore(item, 'valorMaximo', 'first') }}
                                                </ng-container>
                                            </small>
                                            <small>
                                                <ng-container *ngIf="getContentOfStore(item, 'tamanioMaximo')">
                                                    {{ getFormControls(content, item).value?.length || 0 }} /
                                                    {{ getContentOfStore(item, 'tamanioMaximo') }}
                                                </ng-container>
                                            </small>
                                        </div>

                                        <label
                                            [attr.for]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')">
                                            {{ getContentOfStore(item, 'pregunta') || '' }}
                                        </label>
                                    </div>
                                </ng-container>

                            </ng-container>

                            <ng-template #notFloating>
                                <ng-container [ngSwitch]="getContentOfStore(item, 'tipoDePregunta')">
                                    <div class="" style="margin-bottom: 2.1rem;">
                                        <label
                                            [attr.for]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify')">
                                            {{ getContentOfStore(item, 'pregunta') || '' }}
                                        </label>

                                        <ng-container *ngSwitchCase="'radio'">
                                            <ng-container
                                                *ngFor="let option of getContentOfStore(item, 'opciones', 'array'); first as first">
                                                <div class="form-check" [ngClass]="{'mt-1': first}">
                                                    <input type="radio" class="form-check-input"
                                                        [ngClass]="{'is-invalid': getFormControls(content, item).errors &&
                                                        ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                        [attr.name]="getContentOfStore(item, 'pregunta', 'trim', 'slugify')"
                                                        [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify') + '-' + getContentOfStore(option, '', 'trim', 'slugify')"
                                                        [value]="getContentOfStore(option, '', 'trim', 'slugify')"
                                                        (change)="onChangeOptions(getContentOfStore(item, 'tipoDePregunta'), $event, getFormControls(content, item))">

                                                    <label class="form-check-label"
                                                        [attr.for]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify') + '-' + getContentOfStore(option, '', 'trim', 'slugify')">
                                                        {{ getContentOfStore(option) || '' }}
                                                    </label>
                                                </div>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'checkbox'">
                                            <ng-container
                                                *ngFor="let option of getContentOfStore(item, 'opciones', 'array'); first as first">
                                                <div class="form-check" [ngClass]="{'mt-1': first}">
                                                    <input type="checkbox" class="form-check-input"
                                                        [ngClass]="{'is-invalid': getFormControls(content, item).errors &&
                                                        ( getFormControls(content, item).touched || getFormControls(content, item).dirty )}"
                                                        [attr.id]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify') + '-' + getContentOfStore(option, '', 'trim', 'slugify')"
                                                        [value]="getContentOfStore(option, '', 'trim', 'slugify')"
                                                        (change)="onChangeOptions(getContentOfStore(item, 'tipoDePregunta'), $event, getFormControls(content, item))">
                                                    <label class="form-check-label"
                                                        [attr.for]="getContentOfStore(content, 'system_name') + '-' + getContentOfStore(item, 'pregunta', 'trim', 'slugify') + '-' + getContentOfStore(option, '', 'trim', 'slugify')">
                                                        {{ getContentOfStore(option) || '' }}
                                                    </label>
                                                </div>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'boolean'">
                                        </ng-container>

                                        <div class="float-start w-100 d-flex">
                                            <small class="flex-grow-1 text-danger">
                                                <ng-container
                                                    *ngIf="getFormControls(content, item).errors?.required &&
                                                ( getFormControls(content, item).touched || getFormControls(content, item).dirty )">
                                                    Este campo es requerido
                                                </ng-container>
                                            </small>
                                            <small>
                                                <ng-container *ngIf="getContentOfStore(item, 'tamanioMaximo')">
                                                    25 / {{ getContentOfStore(item, 'tamanioMaximo') }}
                                                </ng-container>
                                            </small>
                                        </div>

                                    </div>

                                </ng-container>
                            </ng-template>

                        </ng-container>
                    </form>

                    <ng-container *ngIf="getContentOfStore(messageForm, 'message')">
                        <div class="alert alert-{{ getContentOfStore(messageForm, 'type') }} mb-0 mt-5">
                            {{ getContentOfStore(messageForm, 'message') }}
                        </div>
                    </ng-container>

                </div>

                <div buttonFooter class="d-flex" [ngClass]="{'w-100':getIDForm}">
                    <ng-container *ngIf="getIDForm">
                        <button type="button" class="btn btn-primary me-3 me-auto"
                            (click)="onBackForm(getContentOfStore(content))" [disabled]="getPressButton">
                            Regresar
                        </button>
                    </ng-container>

                    <ng-container *ngFor="let item of getContentOfStore(content, 'botones', 'array')">
                        <ng-container [ngSwitch]="getContentOfStore(item, 'acciónDelBoton')">

                            <ng-container *ngSwitchCase="'cancel'">
                                <button type="button" class="btn btn-light ms-3" (click)="modal.close()"
                                    [disabled]="getPressButton">
                                    {{ getContentOfStore(item, 'etiqueta') }}
                                </button>
                            </ng-container>

                            <ng-container *ngSwitchCase="'save'">
                                <button type="button" class="btn btn-primary ms-3" id="send-form" (click)="onSaveForm()"
                                    [disabled]="getPressButton">
                                    {{ getContentOfStore(item, 'etiqueta') }}
                                </button>
                            </ng-container>

                            <ng-container *ngSwitchCase="'form'">
                                <button type="button" class="btn btn-primary ms-3"
                                    (click)="onNextForm(getContentOfStore(item, 'seleccionaUnFormulario'))"
                                    [disabled]="getPressButton">
                                    {{ getContentOfStore(item, 'etiqueta') }}
                                </button>
                            </ng-container>

                        </ng-container>
                    </ng-container>

                </div>
            </app-modal>
        </ng-template>
    </ng-container>

    <ng-template #modalConfirm let-modal>
        <app-modal class="modal-content">
            <span title>
                Gracias
            </span>

            <button buttonHeader type="button" class="btn-close confirm-send-form" aria-label="Close" (click)="modal.close()"></button>

            <div body>
                <p>
                    Recibimos tus datos correctamente.
                </p>
                <p>
                    En breve nos pondremos en contacto contigo.
                </p>
            </div>

            <div buttonFooter class="d-flex">
                <button type="button" class="btn btn-primary confirm-send-form" (click)="modal.close()">
                    Regresar
                </button>
            </div>
        </app-modal>
    </ng-template>

</ng-container>