import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreObservable {

  constructor() { }
  // store procedure
  private Store = new Subject<any>();
  public Store$ = this.Store.asObservable();
  public changeStore(msg: any): void {
    this.Store.next(msg);
  }
}
