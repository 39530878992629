<div class="modal-header">
    <h4 class="modal-title">
        <ng-content select="[title]"></ng-content>
    </h4>
    <ng-content select="[buttonHeader]"></ng-content>
    <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
</div>
<div class="modal-body">
    <ng-content select="[body]"></ng-content>
</div>
<div class="modal-footer">
    <ng-content select="[buttonFooter]"></ng-content>
    <!-- <button type="button" class="btn btn-light" (click)="modal.close('Close click')">
        Cerrar
    </button> -->
</div>