import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { clone, StoreObservable } from '../';
import { StoreModule } from '../../storeModule';
import Store from '../core';
import { SessionStorage } from '../storageSession';

@Injectable({ providedIn: 'root' })
export class StoreSessionService {
  storageName: string = 'ls_store_procedure'
  static storeCurrent: any = {}
  isBrowser: boolean = false
  reload: boolean = false

  constructor(
    @Inject(PLATFORM_ID) private platformid: any,
    private storeObservable: StoreObservable,
    private storage: SessionStorage,
  ) {
    StoreSessionService.storeCurrent = this.storage.getItem(this.storageName) || {}
    if (isPlatformBrowser(this.platformid)) {
      StoreSessionService.storeCurrent = this.storage.getItem(this.storageName) || {}
      let d: any = window.performance.getEntriesByType("navigation")[0]
      this.isBrowser = true
      this.reload = false
      if (d?.type == 'reload') {
        this.reload = true
      }
    }
  }
  // --
  setStore(method: any) {
    let module: string = method.nameState
    let store: any = StoreSessionService.storeCurrent
    if (!Boolean(store[module])) {
      store[module] = {}
    }
    let data: any = method.initialState
    data = { ...data, ...store[module] }
    if (method?.newState) {
      let d = method?.newState
      if (Array.isArray(d) && d.length == 1) {
        d = d[0]
      }
      data = { ...data, ...d }
    }
    let valueStore: any = {}
    valueStore = clone({ ...store, [module]: { ...data } })
    this.storeObservable.changeStore({
      update: [module],
      value: valueStore
    })
  }
  // --
  removeStore(module: string | string[]) {
    if (!Array.isArray(module)) {
      module = [module]
    }
    let store: any = {}
    let update: string[] = []
    store = StoreSessionService.storeCurrent
    if (Object.keys(store)) {
      module.map(x => {
        if (store[x]) {
          store[x] = StoreModule[x].state.initialState
          update.push(x)
        }
      })
    }
    let valueStore = clone({ ...store })
    this.storeObservable.changeStore({
      update: update,
      value: valueStore
    })
  }
  // ---
  getStoreObservable(module: string): Observable<any> {
    return this.storeObservable.Store$
      .pipe(
        map((store: any) => {
          let value: any = {}
          if (store?.update && Boolean(store.update.length)) {
            store?.update.map((x: string) => {
              if (x == module) {
                value = {
                  [x]: clone(store.value[x])
                }
                if (!Boolean(StoreSessionService.storeCurrent[module])) {
                  StoreSessionService.storeCurrent[module] = {}
                }
                this.storage.setItem(this.storageName, { ...StoreSessionService.storeCurrent, [module]: { ...StoreSessionService.storeCurrent[module], ...store.value[x] } })
                StoreSessionService.storeCurrent = { ...StoreSessionService.storeCurrent, [module]: { ...StoreSessionService.storeCurrent[module], ...store.value[x] } }
              }
            })
          }
          return clone(value)
        })
      )
  }
  // ---
  getStore(module: Store): Observable<any> {
    let name = module.name
    let store: any = StoreSessionService.storeCurrent
    if (Boolean(store[name])) {
      module.contentInit = !module.persistance
      if (!this.reload) {
        module.contentInit = false
      }
    }
    setTimeout(() => {
      if (store[name]) {
        this.storeObservable.changeStore({
          update: [name],
          value: clone({ ...store, [name]: { ...StoreModule[name].state.initialState, ...store[name] } })
        })
      }
      else {
        store = this.setStore(StoreModule[name].state)
      }
    })
    return this.getStoreObservable(name)
  }
}
