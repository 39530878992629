import { Component, Input, OnInit } from '@angular/core';
import { JSONTryer } from '../../functions';

const EStyle = {
  Lineal: 'lineal',
  Circle: 'circle',
  Square: 'square',
}

@Component({
  selector: 'app-shadow',
  templateUrl: './shadow.component.html',
  styleUrls: ['./shadow.component.less']
})
export class ShadowComponent implements OnInit {
  @Input() type: string = EStyle.Lineal
  @Input() count: number | string = 0
  @Input() width: number | string = 0
  @Input() height: number | string = 'auto'

  styles = EStyle

  default: any = {
    width: 350,
    height: 'auto',
    count: 1,
    random: false,
  }

  elements: any[] = []

  constructor() { }

  ngOnInit(): void {
    if (!Boolean(this.type)) {
      this.type = EStyle.Lineal
    }
    // --
    this.count = JSONTryer(this.count)
    if (!Boolean(this.count)) {
      this.count = this.default.count
    }
    // --
    this.width = JSONTryer(this.width)
    if (!Boolean(this.width)) {
      this.width = this.default.width
    }
    // --
    this.height = JSONTryer(this.height)
    if ((typeof this.height != 'string' && !Boolean(this.height)) || (typeof this.height == 'string' && this.height != 'auto')) {
      this.height = this.default.height
    }
    // --
    for (let i = 0; i < this.count; i++) {
      this.elements.push({
        type: this.type,
        width: this.width,
        height: this.height
      })
    }
  }
}
