import { Base64 } from 'js-base64';

const encryptionTimes = 2;
/**
 * Function that encrypts a given string [encryptionTimes] times
 * @param toEncrypt String to encrypt
 * @param current Number of current encryptions
 * @returns Base64 encrypted string
 * @example ("_ls_session_access_token") will return "X2xzX3Nlc3Npb25fYWNjZXNzX3Rva2Vu"
 */
export const encrypt = (toEncrypt: string, current: number = 1): string => {
    // let bcrypt = new Bcrypt;
    if (toEncrypt) {
        if (current <= encryptionTimes) {
            return encrypt(Base64.encode(toEncrypt), current + 1);
        }
    }
    return toEncrypt;
}
/**
 * Function that decrypts a given string [encryptionTimes] times
 * @param toDecrypt String to encrypt
 * @param current Number of current decryptions
 * @returns Base64 decrypted string
 * @example ("X2xzX3Nlc3Npb25fYWNjZXNzX3Rva2Vu") will return "_ls_session_access_token"
 */
export const decrypt = (toDecrypt: string, current: number = 1): string => {
    // let bcrypt = new Bcrypt;
    if (toDecrypt) {
        if (current <= encryptionTimes) {
            return decrypt(Base64.decode(toDecrypt), current + 1);
        }
    }
    return toDecrypt;
}