import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { decrypt, encrypt, JSONTryer } from './';
import { MemoryStorage } from './storageMemory';

@Injectable({ providedIn: 'root' })
export class SessionStorage implements Storage {
  private readonly storage: Storage | null = null

  constructor(
    @Inject(PLATFORM_ID) private platformid: any,
  ) {
    if (isPlatformBrowser(this.platformid)) {
      this.storage = window.sessionStorage
    }
    else {
      this.storage = new MemoryStorage()
    }
  }

  get length(): number {
    if (this.storage) {
      return this.storage.length
    }
    return 0
  }

  clear(): void {
    if (this.storage) {
      this.storage.clear()
    }
  }

  getItem(key: string): string | null {
    let data: string = ''
    if (this.storage && key) {
      data = this.storage.getItem(key) || ''
      if (environment.production) {
        data = this.storage.getItem(encrypt(key)) || ''
        data = decrypt(data)
      }
    }
    return Boolean(data) ? JSONTryer(data) : null
  }

  key(index: number): string | null {
    if (this.storage) {
      return this.storage.key(index)
    }
    return null
  }

  removeItem(key: string | string[]): void {
    if (environment.production) {
      if (typeof key == 'string') {
        if (this.storage) {
          this.storage.removeItem(encrypt(key))
        }
      }
      else {
        key.map(item => {
          if (this.storage) {
            this.storage.removeItem(encrypt(item))
          }
        })
      }
    } else {
      if (typeof key == 'string') {
        if (this.storage) {
          this.storage.removeItem(key)
        }
      }
      else {
        key.map(item => {
          if (this.storage) {
            this.storage.removeItem(item)
          }
        })
      }
    }
  }

  setItem(key: string, value: any): void {
    if (environment.production) {
      if (this.storage) {
        this.storage.setItem(encrypt(key), encrypt(JSON.stringify(value)))
      }
    } else {
      if (this.storage) {
        this.storage.setItem(key, JSON.stringify(value))
      }
    }
  }
}