import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericObservableService {

  constructor() { }

  // --- Generic
  private generic = new Subject<any>();
  public generic$ = this.generic.asObservable();
  public genericChange(value: any): void {
    this.generic.next(value);
  }
}
