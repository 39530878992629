import { Injectable } from '@angular/core';
import { ApiService, SettingsService } from 'src/app/_core/services';

@Injectable({
  providedIn: 'root',
})
export class CMSService {
  private pathInfo: any = {}
  private baseUrl: any = {}
  private segment: any = {}

  constructor(
    private settings: SettingsService,
    private api: ApiService,
  ) {
    this.pathInfo = this.settings.get('pathInfo')
    this.baseUrl = this.pathInfo.base.baseUrlCms
    this.segment = this.pathInfo.res.cms
  }

  async postSession(headers: any = {}) {
    let endpoint = this.baseUrl + this.segment.signin
    let body = {
      "provider": "auto",
      "isPasswordEncrypted": false,
      "password": "formulario-front",
      "username": "formularios@abargon.com"
    }
    return await this.api.withAsync(() => this.api.post(endpoint, body, { ...headers }).toPromise().then((response: any) => response))
  }

  async getContentType(token: string) {
    let endpoint = this.baseUrl + this.segment.contentType
    return await this.api.withAsync(() => this.api.get(endpoint, { 'X-Auth-Token': token }).toPromise().then((response: any) => response))
  }

  async postContent(token: string, body: any) {
    let endpoint = this.baseUrl + this.segment.contentCMS
    return await this.api.withAsync(() => this.api.post(endpoint, body, { 'X-Auth-Token': token }).toPromise().then((response: any) => response))
  }

  async sendEmail(body: any, headers: any = {}) {
    let endpoint = this.baseUrl + this.segment?.email
    return await this.api.withAsync(() => this.api.post(endpoint, body, { ...headers }).toPromise().then((response: any) => response))
  }
}
