import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeHeaders } from '../functions';
import { LoaderObservableService, ModalObservableService } from '../observables';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private modalObservable: ModalObservableService,
    private loaderObservable: LoaderObservableService,
    private httpClient: HttpClient,
  ) { }

  get(url: string, headers: any = {}): any {
    this.loaderObservable.loaderChange(true)
    this.modalObservable.modalChange()
    return this.httpClient.get(url, makeHeaders(headers))
  }
  // --
  post(url: string, body: any, headers: any = {}): any {
    this.loaderObservable.loaderChange(true)
    this.modalObservable.modalChange()
    return this.httpClient.post(url, body, makeHeaders(headers))
  }
  // --
  put(url: string, body: any, headers: any = {}): any {
    this.loaderObservable.loaderChange(true)
    this.modalObservable.modalChange()
    return this.httpClient.put(url, body, makeHeaders(headers))
  }
  // --
  delete(url: string, headers: any = {}): any {
    this.loaderObservable.loaderChange(true)
    this.modalObservable.modalChange()
    return this.httpClient.delete(url, makeHeaders(headers))
  }
  // --
  withAsync = async (fn: Function) => {
    try {
      const response = await fn()
      return [response, null]
    } catch (error) {
      return [null, error]
    } finally {

    }
  }
}
