export const JSONTryer = (jsonString: any) => {
    try {
        return JSON.parse(jsonString)
    } catch (e) {
        return jsonString
    }
}

export const replaceText = (text: string, object: any, lowercase: boolean = false) => {
    Object.keys(object).map((item) => {
        if (lowercase) {
            text = text.replace(item, ((object[item]).toString()).toLowerCase())
        }
        else {
            text = text.replace(item, ((object[item]).toString()))
        }
    });
    return text;
};

export function slugify(input: string, char_separetor = '-'): string {
    if (input) {
        return replaceSpecialChars(input, char_separetor).replace(/[^a-z0-9\-\s]/g, char_separetor) // remove invalid chars
            .replace(/[\\\/\(\)]+/g, char_separetor) // collapse whitespace and replace by -
            .replace(/[\s]+/g, char_separetor) // collapse whitespace and replace by -
            .replace(/[-]+/g, char_separetor) // collapse dashes
    }
    return input
}

export function replaceSpecialChars(input: string, char_separetor = '-'): string {
    let data: string = '' + input
    if (data) {
        data = data.toLowerCase()
        data = data.replace(/^\s+|\s+$/g, char_separetor)
        data = data.toLowerCase()
        const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/%#_:;,'
        const to = 'aaaaaeeeeiiiioooouuuunc--------'.replace(/-/g, char_separetor)
        from.split('').map((x: any, i: number) => {
            data = data.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        })
    }
    return data
}

export const getBase64FromUrl = async (url: string) => {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            const base64data = reader.result
            resolve(base64data)
        }
    });
}
// --
// export const imageDownload = (url) => {
//     if (undefined === window.navigator.msSaveOrOpenBlob) {
//         let e = document.createElement('a');
//         getBase64FromUrl(url).then((dataUrl: any) => {
//             e.setAttribute('href', dataUrl);
//             e.setAttribute('download', url.split('/').pop());
//             document.body.appendChild(e);
//             e.click();
//         })
//     }
// }
// --
export function clone(data: any): any {
    let copy: any;
    if (null == data || undefined == data) return data;
    // Array
    if (typeof data == 'object' && Array.isArray(data)) {
        copy = [];
        for (let i = 0, len = data.length; i < len; i++) {
            copy[i] = clone(data[i]);
        }
        return copy;
    }
    // object
    if (typeof data == 'object' && !Array.isArray(data)) {
        copy = {};
        for (let attr in data) {
            if (data.hasOwnProperty(attr)) copy[attr] = clone(data[attr]);
        }
        return copy;
    }
    // --
    return JSON.parse(JSON.stringify(data))
}
// --
export const getBrowserLanguage = () => {
    var lang = navigator.language || 'es-'
    return lang.split('-')[0]
}
// --
// export const translateObjectValues = (data: object): object => {
//     let transLateFilter: TranslatePipe = new TranslatePipe()
//     Object.keys(data).map(item => {
//         if (typeof data[item] == 'string') {
//             data[item] = transLateFilter.transform(data[item])
//         }
//         else if (typeof data[item] == 'object') {
//             translateObjectValues(data[item])
//         }
//     })
//     return data
// }

export const generatePassword = (min: number, max: number) => {
    let length = min,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789$&?!#@^",
        retVal = ""

    do {
        length = Math.floor(Math.random() * (max))
    } while (length < min || length > max)

    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
    }
    return retVal
}

export const removeHTMLElementForText = (data: string): string => {
    return data.replace(/(<([^>]+)>)/gi, "").trim()
}

// --
export const isExternalLink = (url: string): boolean => {
    var re = new RegExp("^(http|https)://", "gi")
    return re.test(url)
}